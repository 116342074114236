import { createContext,useState } from "react";
import React from "react";

export const ThemeContext = createContext();

export default function ThemeContextProvider({children}){
    const [changeNavColor,setChangeNavColor] = useState(false);
    const [themeMode,setThemeMode]= useState("light");
    const [showModal, setShowModal] = useState(false);
    const [showFilterMobile, setShowFilterMobile] = useState(false);
    return(
        <ThemeContext.Provider value={{
            changeNavColor,setChangeNavColor,themeMode,setThemeMode,
            showModal, setShowModal,showFilterMobile, setShowFilterMobile
        }}>
            {children}
        </ThemeContext.Provider>
    )
}